//const gatewaysCacheKey = 'betternetworks:gateways';
//const devicesCacheKey = 'betternetworks:devices';
//let VM;

//import moment from 'moment';

export default {
  initUpdator(/*self */) {
    //console.log('initUpdator')
    //let me = this;
    //VM = this;
    /*self.$eventBus.on( "ws-networks-device-update", async ( updateData ) => {
      await me.getDevices( self, null, true );
      return self.$eventBus.emit( 'networks-device-update', updateData );
    } );
    self.$eventBus.on( "ws-networks-templates-update", ( updateData ) => {
      //console.log('UPDATE TRIGGER: ' + updateData.id);
      me.getTemplates( self, null, true ).then( () => {} ).catch( () => {} );
      me.getTemplates( self, updateData.id, true ).then( () => {} ).catch( () => {} );
      if ( !self.$tools.isNullOrUndefined( updateData.draftVersion ) ) {
        //console.log('UPDATE TRIGGER: ' + `${updateData.id}.${updateData.draftVersion}`);
        me.getTemplates( self, `${updateData.id}.${updateData.draftVersion}`, true ).then( () => {} ).catch( () => {} );
      }
    } );
    self.$eventBus.on( "ws-networks-gateway-syslog-result", syslog => {
      let syslogList = [];
      let sysLogData = window.sessionStorage.getItem( 'syslogs' );
      if ( !self.$tools.isNullOrUndefined( sysLogData ) ) {
        syslogList = JSON.parse( sysLogData );
      }
      me.getDevices( self )
        .then( deviceList => {
          for ( let device of deviceList ) {
            if ( device.id !== syslog.deviceId ) continue;
            return self.$eventBus.emit( 'notification', {
              value: {
                title: syslog.result.message,
                color: syslog.result.message.indexOf( 'error' ) < 0 ? 'info' : 'danger',
                icon: 'flaticon-layer',
                postNote: device.name,
                link: `/Networks/Syslog/${syslog.gatewayId}-${syslog.deviceId}-${syslog.result.date}-${syslog.result.gatewayTime}`
              },
              time: new Date( syslog.result.date ).getTime(),
              type: 'events'
            } );
          }
        } );

      syslogList.push( syslog );

      if ( syslogList.length > 100 ) {
        syslogList.splice( 100 );
      }
      window.sessionStorage.setItem( 'syslogs', JSON.stringify( syslogList ) );

      self.$eventBus.emit( 'gateway-syslog', syslog );
    } );*/
  },
  getForms: (self, serviceId, offset = 0, limit = 100) =>
    new Promise((resolve, reject) => {
      /*self.$api.getWithCache(self,
          `whatsapp`,
          `${self.$root.clientId}/${serviceId}/profiles?offset=${offset}&limit=${limit}`,
          `communication-whatsapp-profiles-${self.$root.clientId}-${serviceId}-${offset}-${limit}`
        )
        .then(x => resolve({
          count: x.count,
          data: x.data
        }))
        .catch(reject);*/
      self.$api
        .get(
          `forms`,
          `${
            self.$root.clientId
          }/${serviceId}/forms?offset=${offset}&limit=${limit}`
        )
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
  getFormsApps: (self, serviceId) =>
    new Promise((resolve, reject) => {
      /*self.$api.getWithCache(self,
          `whatsapp`,
          `${self.$root.clientId}/${serviceId}/profiles?offset=${offset}&limit=${limit}`,
          `communication-whatsapp-profiles-${self.$root.clientId}-${serviceId}-${offset}-${limit}`
        )
        .then(x => resolve({
          count: x.count,
          data: x.data
        }))
        .catch(reject);*/
      self.$api
        .get(
          `forms`,
          `${
            self.$root.clientId
          }/${serviceId}/forms-apps`
        )
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
  newForm: (self, serviceId, name, description, renderAppId) =>
    new Promise((resolve, reject) => {
      /*self.$api.getWithCache(self,
          `whatsapp`,
          `${self.$root.clientId}/${serviceId}/profiles?offset=${offset}&limit=${limit}`,
          `communication-whatsapp-profiles-${self.$root.clientId}-${serviceId}-${offset}-${limit}`
        )
        .then(x => resolve({
          count: x.count,
          data: x.data
        }))
        .catch(reject);*/
      self.$api
        .post2(`forms`, `${self.$root.clientId}/${serviceId}/forms`, {
          name,
          description,
          renderAppId
        })
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
  updateForm: (self, serviceId, formId, data) =>
    new Promise((resolve, reject) => {
      /*self.$api.getWithCache(self,
          `whatsapp`,
          `${self.$root.clientId}/${serviceId}/profiles?offset=${offset}&limit=${limit}`,
          `communication-whatsapp-profiles-${self.$root.clientId}-${serviceId}-${offset}-${limit}`
        )
        .then(x => resolve({
          count: x.count,
          data: x.data
        }))
        .catch(reject);*/
      self.$api
        .update2(`forms`, `${self.$root.clientId}/${serviceId}/forms/${formId}`, undefined, data)
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
  getFormResponses: (self, serviceId, formId) =>
    new Promise((resolve, reject) => {
      /*self.$api.getWithCache(self,
          `whatsapp`,
          `${self.$root.clientId}/${serviceId}/profiles?offset=${offset}&limit=${limit}`,
          `communication-whatsapp-profiles-${self.$root.clientId}-${serviceId}-${offset}-${limit}`
        )
        .then(x => resolve({
          count: x.count,
          data: x.data
        }))
        .catch(reject);*/
      self.$api
        .get(`forms`, `${self.$root.clientId}/${serviceId}/forms/${formId}`)
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
  publishForm: (self, serviceId, formId) =>
    new Promise((resolve, reject) => {
      /*self.$api.getWithCache(self,
          `whatsapp`,
          `${self.$root.clientId}/${serviceId}/profiles?offset=${offset}&limit=${limit}`,
          `communication-whatsapp-profiles-${self.$root.clientId}-${serviceId}-${offset}-${limit}`
        )
        .then(x => resolve({
          count: x.count,
          data: x.data
        }))
        .catch(reject);*/
      self.$api
        .put2(`forms`, `${self.$root.clientId}/${serviceId}/forms/${formId}`, undefined, {})
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
  getProfiles: (self, serviceId, offset = 0, limit = 100) =>
    new Promise((resolve, reject) => {
      /*self.$api.getWithCache(self,
          `whatsapp`,
          `${self.$root.clientId}/${serviceId}/profiles?offset=${offset}&limit=${limit}`,
          `communication-whatsapp-profiles-${self.$root.clientId}-${serviceId}-${offset}-${limit}`
        )
        .then(x => resolve({
          count: x.count,
          data: x.data
        }))
        .catch(reject);*/
      self.$api
        .get(
          `whatsapp`,
          `${
            self.$root.clientId
          }/${serviceId}/profiles?offset=${offset}&limit=${limit}`
        )
        .then((x) =>
          resolve({
            count: x.data.count,
            data: x.data.data,
          })
        )
        .catch(reject);
    }),
  getMessages: (self, serviceId, profileId, offset = 0, limit = 100) =>
    new Promise((resolve, reject) => {
      /*self.$api.getWithCache(self,
          `advertising`,
          `${self.$root.clientId}/${serviceId}/messages/${profileId}?offset=${offset}&limit=${limit`,
          `communication-whatsapp-messages-${self.$root.clientId}-${serviceId}-${profileId}-${offset}-${limit}`
        )
        .then(x => resolve({
          count: x.count,
          data: x.data
        }))
        .catch(reject);*/
      self.$api
        .get(
          `whatsapp`,
          `${
            self.$root.clientId
          }/${serviceId}/messages/${profileId}?offset=${offset}&limit=${limit}`
        )
        .then((x) =>
          resolve({
            count: x.data.count,
            data: x.data.data,
          })
        )
        .catch(reject);
    }),
  getMessageMedia: (self, serviceId, profileId, messageId, mediaIndex = 0) =>
    new Promise((resolve, reject) => {
      /*self.$api.getWithCache(self,
          `advertising`,
          `${self.$root.clientId}/${serviceId}/messages/${profileId}?offset=${offset}&limit=${limit`,
          `communication-whatsapp-messages-${self.$root.clientId}-${serviceId}-${profileId}-${offset}-${limit}`
        )
        .then(x => resolve({
          count: x.count,
          data: x.data
        }))
        .catch(reject);*/
      self.$api
        .get(
          `whatsapp`,
          `${
            self.$root.clientId
          }/${serviceId}/messages/${profileId}/${messageId}/${mediaIndex}`
        )
        .then((x) => resolve(x.data.url))
        .catch(reject);
    }),
  sendMessage: (self, serviceId, number, message) =>
    new Promise((resolve, reject) => {
      /*self.$api.getWithCache(self,
          `advertising`,
          `${self.$root.clientId}/${serviceId}/messages/${profileId}?offset=${offset}&limit=${limit`,
          `communication-whatsapp-messages-${self.$root.clientId}-${serviceId}-${profileId}-${offset}-${limit}`
        )
        .then(x => resolve({
          count: x.count,
          data: x.data
        }))
        .catch(reject);*/
      self.$api
        .post2(`whatsapp`, `${self.$root.clientId}/${serviceId}/messages`, {
          message,
          to: number,
        })
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
};
