<!-- eslint-disable -->

<template>
  <div class="row">
    <!--begin::Aside-->
    <div dclass="flex-row-auto offcanvas-mobile w-350px w-xl-400px"
      :class="`col-md-12 col-lg-5 col-xl-3 ${ selectedProfile !== null ? 'd-none d-lg-block' : '' }`">
      <!--begin::Card-->
      <KTPortlet :heightContent="true" title="Contacts" cadBodyStyle="max-height: 600px; overflow-y: scroll;">
        <!-- <template v-slot:toolbar v-if="start !== null && end !== null">
          {{ fstart }} - {{ fend }}
        </template> -->
        <template v-slot:toolbar>
          <b-button v-if="filteredProfiles !== null" @click="newChat" style="text-align: center;"
            variant="info text-light" class="ma-1">NEW CHAT
          </b-button>
        </template>
        <template slot="body">
          <!--begin:Search-->
          <div v-if="false" class="input-group input-group-solid">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <span class="svg-icon svg-icon-lg">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000" fill-rule="nonzero" opacity="0.3" />
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        fill="#000000" fill-rule="nonzero" />
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </span>
            </div>
            <v-input type="text" class="form-control " placeholder="Email" />
          </div>
          <!--end:Search-->
          <!--begin:Users-->

          <div class="mt-7">
            <!--begin:User-->
            <template v-if="filteredProfiles === null">
              <div class="d-flex align-items-center justify-content-between mb-5" v-for="i in 5" v-bind:key="i">
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column">
                    <span class="text-dark-75 text-hover-primary font-weight-bold font-size-lg">
                      <Loader :newStyle="1" :width="300" />
                    </span>
                    <span class="text-muted font-weight-bold font-size-sm">
                      <Loader :newStyle="1" :width="200" />
                    </span>
                  </div>
                  <div class="d-flex flex-column align-items-end">
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex align-items-center justify-content-between mb-5" v-for="profile in filteredProfiles"
                v-bind:key="profile.id">
                <div class="d-flex align-items-center">
                  <!-- <div class="symbol symbol-circle symbol-50 mr-3">
                  <img
                    alt="Pic"
                    src="https://www.gravatar.com/a"
                  />
                </div> -->
                  <div class="d-flex flex-column">
                    <router-link :to="
                      `/Communication/${ $route.params.serviceId }/WhatsappChat/${ profile.id
                      }`
                    " class="text-dark-75 text-hover-primary font-weight-bold font-size-lg">{{ profile.name ||
    profile.number
}}</router-link>
                    <span class="text-muted font-weight-bold font-size-sm">{{
                        profile.number
                    }}</span>
                  </div>
                </div>
                <div class="d-flex flex-column align-items-end">
                  <!-- <span class="text-muted font-weight-bold font-size-sm"
                  >35 mins</span
                > -->
                  <div v-if="profile.locked">
                    <v-chip style="text-align: center;" color="bg-danger text-dark" class="ma-2" x-small>CHAT WINDOW
                      CLOSED</v-chip>
                  </div>
                  <div v-else>
                    <v-chip v-if="profile.waitingReply" style="text-align: center;" color="bg-warning text-dark"
                      class="ma-2" x-small>AWAITING REPLY</v-chip>
                    <v-chip v-if="profile.urgent" style="text-align: center;" color="bg-danger text-dark" class="ma-2"
                      x-small>CHAT CLOSING</v-chip>
                  </div>
                </div>
              </div>
            </template>
            <!--end:User-->
          </div>
          <!--end:Users-->
        </template>
        <!--end::Body-->
      </KTPortlet>
      <!--end::Card-->
    </div>
    <!--end::Aside-->
    <!--begin::Content-->
    <div class="col-md-12 col-lg-7 col-xl-9" id="kt_chat_content" v-if="selectedProfile !== null">
      <!--begin::Card-->
      <KTPortlet :heightContent="true" ref="messagesPortlet" :xtitle="
        `Chat with ${ selectedProfile.name || selectedProfile.number }${ selectedProfile.name !== null ? ` (${ selectedProfile.number })` : ''
        }`
      " xadBodyStyle="max-height: 600px; overflow-y: scroll;">
        <!-- <template v-slot:toolbar v-if="start !== null && end !== null">
          {{ fstart }} - {{ fend }}
        </template> -->
        <template v-slot:titlx>
          <div v-if="false" class="text-left flex-grow-1">
            <!--begin::Aside Mobile Toggle-->
            <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-md d-lg-none" id="kt_app_chat_toggle">
              <span class="svg-icon svg-icon-lg">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Adress-book2.svg-->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z"
                      fill="#000000" opacity="0.3" />
                    <path
                      d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z"
                      fill="#000000" />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </button>
            <!--end::Aside Mobile Toggle-->
            <!--begin::Dropdown Menu-->
            <div class="dropdown dropdown-inline">
              <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="ki ki-bold-more-hor icon-md"></i>
              </button>
              <div class="dropdown-menu p-0 m-0 dropdown-menu-left dropdown-menu-md">
                <!--begin::Navigation-->
                <ul class="navi navi-hover py-5">
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-drop"></i>
                      </span>
                      <span class="navi-text">New Group</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-list-3"></i>
                      </span>
                      <span class="navi-text">Contacts</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-rocket-1"></i>
                      </span>
                      <span class="navi-text">Groups</span>
                      <span class="navi-link-badge">
                        <span class="label label-light-primary label-inline font-weight-bold">new</span>
                      </span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-bell-2"></i>
                      </span>
                      <span class="navi-text">Calls</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-gear"></i>
                      </span>
                      <span class="navi-text">Settings</span>
                    </a>
                  </li>
                  <li class="navi-separator my-3"></li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-magnifier-tool"></i>
                      </span>
                      <span class="navi-text">Help</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-bell-2"></i>
                      </span>
                      <span class="navi-text">Privacy</span>
                      <span class="navi-link-badge">
                        <span class="label label-light-danger label-rounded font-weight-bold">5</span>
                      </span>
                    </a>
                  </li>
                </ul>
                <!--end::Navigation-->
              </div>
            </div>
            <!--end::Dropdown Menu-->
          </div>
          <div class="text-left flex-grow-1">
            <div class="text-dark-75 font-weight-bold font-size-h5">
              {{ selectedProfile.name || selectedProfile.number }}
            </div>
            <div>
              <!-- <span
                class="label label-md label-dot label-success"
                style="margin-right: 5px;"
              ></span> -->
              <span class="font-weight-bold text-muted font-size-sm">{{
                  selectedProfile.number
              }}</span>
            </div>
          </div>
        </template>
        <template v-slot:title>
          <div class="text-left flex-grow-1 d-lg-none">
            <router-link :to="
            `/Communication/${ $route.params.serviceId }/WhatsappChat`">
              <i style="padding: 10px; background: var(--primary); border-radius: 50%; color: var(--light); width: 30px; height: 30px; vertical-align: middle; margin-right: 10px;"
                class="fas fa-angle-double-left"></i>
            </router-link>
          </div>
          <h3 class="kt-portlet__head-title">
            Chat with {{ selectedProfile.name || selectedProfile.number }}{{ selectedProfile.name !== null ? ` (${ selectedProfile.number })` : ''
            }}
          </h3>
        </template>
        <template v-slot:toolbard v-if="false">
          <div class="text-right flex-grow-1">
            <!--begin::Dropdown Menu-->
            <div class="dropdown dropdown-inline">
              <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="svg-icon svg-icon-lg">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Add-user.svg-->
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                        fill="#000000" fill-rule="nonzero" opacity="0.3" />
                      <path
                        d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                        fill="#000000" fill-rule="nonzero" />
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </button>
              <div class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-md">
                <!--begin::Navigation-->
                <ul class="navi navi-hover py-5">
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-drop"></i>
                      </span>
                      <span class="navi-text">New Group</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-list-3"></i>
                      </span>
                      <span class="navi-text">Contacts</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-rocket-1"></i>
                      </span>
                      <span class="navi-text">Groups</span>
                      <span class="navi-link-badge">
                        <span class="label label-light-primary label-inline font-weight-bold">new</span>
                      </span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-bell-2"></i>
                      </span>
                      <span class="navi-text">Calls</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-gear"></i>
                      </span>
                      <span class="navi-text">Settings</span>
                    </a>
                  </li>
                  <li class="navi-separator my-3"></li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-magnifier-tool"></i>
                      </span>
                      <span class="navi-text">Help</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-bell-2"></i>
                      </span>
                      <span class="navi-text">Privacy</span>
                      <span class="navi-link-badge">
                        <span class="label label-light-danger label-rounded font-weight-bold">5</span>
                      </span>
                    </a>
                  </li>
                </ul>
                <!--end::Navigation-->
              </div>
            </div>
            <!--end::Dropdown Menu-->
          </div>
        </template>
        <!--end::Header-->
        <!--begin::Body-->
        <template slot="body" class="card-body">
          <!--begin::Scroll-->
          <div class="scroll scroll-pull" data-mobile-height="350">
            <!--begin::Messages-->
            <div class="messages" style="padding-right: 10px;">
              <!--begin::Message In-->
              <div class="d-flex flex-column mb-2 align-items-center" v-if="filteredMessages === null">
                <Loader text="Loading messages" />
              </div>
              <div class="d-flex flex-column mb-2 align-items-center" v-else-if="filteredMessages.length === 0">
                <v-chip class="bg-grey text-dark p-3 mt-5" style="height: auto;">
                  No messages
                </v-chip>
                <v-chip class="bg-grey text-dark p-3 mt-5" style="height: auto;">
                  Send one to start the conversation :)
                </v-chip>
              </div>
              <template v-else>
                <div v-for="message in filteredMessages" v-bind:key="message.id" :class="
                  `d-flex flex-column mb-2 align-items-${ message.internal === true
                    ? 'center'
                    : message.direction === 'inbound'
                      ? 'start'
                      : 'end'
                  }`
                ">
                  <div class="d-flex align-items-center text-primary" v-if="message.showUserBanner">
                    <!-- <div class="symbol symbol-circle symbol-35 mr-3">
                    <img
                      alt="Pic"
                      src="https://www.gravatar.com/avata"
                    />
                  </div> -->
                    <div>
                      <span href="#" class="text-dark-75 text-hover-primary font-weight-bold font-size-h6">{{
                          message.direction === "inbound"
                            ? selectedProfile.name || selectedProfile.number
                            : "You"
                      }}</span>
                      <!-- <span class="text-muted font-size-sm">2 Hours</span> -->
                    </div>
                  </div>
                  <v-chip :class="
                    `p-3 font-weight-bold font-size-lg text-left max-w-400px ${ message.direction === 'inbound'
                      ? 'bg-success text-light'
                      : 'bg-primary text-light'
                    }`
                  " style="height: auto; padding-bottom: 20px !important; min-width: 200px;"
                    v-if="message.internal !== true">
                    <div style="display: block; flex-direction: column; align-items: flex-start; "
                      v-if="!$tools.isNullOrUndefined(message.message) && $tools.isString(message.message.body) && message.message.body !== ''">
                      <span style="display: block; white-space: pre-wrap;" v-for="(line, index) of message.message.body.split(
                        '\n'
                      )" v-bind:key="index">
                        {{ line }}
                      </span>
                    </div>
                    <div v-for="media in message.media" v-bind:key="media.url">
                      <template v-if="typeof media.contentType !== 'string'"></template>
                      <img :src="media.url" v-else-if="media.contentType.indexOf('image/') >= 0"
                        style="width: 200px;height: 200px;border-radius: 16px;" />
                      <video :src="media.url" v-else-if="media.contentType.indexOf('video/') >= 0"
                        style="object-fit: fill; height: 150px;" :type="media.contentType" controls />
                      <audio :src="media.url" v-else-if="media.contentType.indexOf('audio/') >= 0"
                        xstyle="object-fit: fill; height: 150px;" :type="media.contentType" controls />
                      <a :href="media.url" target="_blank" v-else>DOWNLOAD</a>
                    </div>
                    <span class="text-light"
                      style="opacity: 0.5; font-size: xx-small; display: block; position: absolute; bottom: 0; margin-bottom: -2px;">{{
                          formatDT(message.date)
                      }}{{ message.direction === 'outbound' ? ` - ${ message.status.toLowerCase() }` : '' }}</span>
                  </v-chip>
                  <template v-else>
                    <v-chip class="bg-gray text-dark mt-2">
                      {{ message.internalTitle }}
                    </v-chip>
                    <span style="opacity: 0.5; color: var(--gray); font-size: xx-small;">{{ formatDT(message.date)
                    }}</span>
                  </template>
                </div>
              </template>
              <!--end::Message In-->
              <!--begin::Message Out-->
              <!-- <div class="d-flex flex-column mb-5 align-items-end">
                <div class="d-flex align-items-center">
                  <div>
                    <span class="text-muted font-size-sm">3 minutes</span>
                    <a
                      href="#"
                      class="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                      >You</a
                    >
                  </div>
                  <div class="symbol symbol-circle symbol-35 ml-3">
                    <img
                      alt="Pic"
                      src="https://www.gravatar.com/avatar/a32c49cf7a751cfbaa02cc9e5784b3db?d=https%3A%2F%2Fbetterportal.cloud%2Fassets%2Fmedia%2F_bcorp%2FBetterNINJA-ICON.jpg"
                    />
                  </div>
                </div>
                <div class="mt-2 rounded p-5 card text-dark-50 font-weight-bold font-size-lg text-right max-w-400px"
                >
                  Hey there, we’re just writing to let you know that you’ve been
                  subscribed to a repository on GitHub.
                </div>
              </div> -->
              <!--end::Message Out-->
            </div>
            <!--end::Messages-->
          </div>
          <!--end::Scroll-->
        </template>
        <!--end::Body-->
        <!--begin::Footer-->
        <template v-slot:foot class="card-footer"
          v-if="selectedProfile !== null && selectedProfile.lastRecievedMessage > nowMinus24h">
          <div class="align-items-center text-center">
            <!--begin::Compose-->
            <textarea @keydown.enter.exact.prevent="
              sendMessage(selectedProfile.number, message)
            " @keydown.enter.shift.exact.prevent="message += '\n'" v-model="message"
              class="form-control border-0 p-0 no-border-radius" rows="4" placeholder="Type a message"></textarea>
            <div class="d-flex align-items-center justify-content-between mt-3">
              <div class="mr-3">
                <!-- <a href="#" class="btn btn-clean btn-icon btn-md mr-1">
                <i class="flaticon2-photograph icon-lg"></i>
              </a>
              <a href="#" class="btn btn-clean btn-icon btn-md">
                <i class="flaticon2-photo-camera icon-lg"></i>
              </a> -->
              </div>
              <div>
                <span style="margin-right: 10px;" :class="
                  `text-${ conversationTimeLeft.urgent
                    ? 'danger'
                    : conversationTimeLeft.warn
                      ? 'warning'
                      : 'dark'
                  }`
                ">
                  Conversation time left:
                  <b>{{ conversationTimeLeft.text }}</b>
                </span>
                <b-button @click="sendMessage(selectedProfile.number, message)" type="button"
                  class="btn text-light btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6">
                  Send
                </b-button>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:foot v-else class="card-footer">
          <div class="align-items-center text-center">
            <textarea :disabled="true" class="form-control border-0 p-0 no-border-radius" rows="4"
              placeholder="Type a message">
*WARNING* The conversation with this profile has timed out, to start the chat with them again, send a template</textarea>
            <div class="d-flex align-items-center justify-content-between mt-3">
              <div class="mr-3">
                <!-- <a href="#" class="btn btn-clean btn-icon btn-md mr-1">
                <i class="flaticon2-photograph icon-lg"></i>
              </a>
              <a href="#" class="btn btn-clean btn-icon btn-md">
                <i class="flaticon2-photo-camera icon-lg"></i>
              </a> -->
              </div>
              <div>
                <b-button variant="primary text-light" @click="
                    sendMessage(
                      selectedProfile.number,
                      `Hello ${
                        (selectedProfile.name || 'there').split(' ')[0]
                      },\n\n Are you available for us to chat?`
                    )
                  ">Hello {{ (selectedProfile.name || "there").split(" ")[0] }},
                  Are you available for us to chat?</b-button>
              </div>
            </div>
            <!-- <div>
              <b-button
                variant="primary text-light"
                @click="
                  sendMessage(
                    selectedProfile.number,
                    `Hello ${
                      (selectedProfile.name || 'there').split(' ')[0]
                    },\n\n Are you available for us to chat?`
                  )
                "
                >Hello {{ (selectedProfile.name || "there").split(" ")[0] }},
                Are you available for us to chat?</b-button
              >
            </div> -->
          </div>
          <!--begin::Compose-->
        </template>
        <!--end::Footer-->
      </KTPortlet>
      <!--end::Card-->
    </div>
    <!--end::Content-->
    <b-modal ref="newchatmodal" title="New Chat" :hide-footer="true">
      <div class="align-items-center text-center">
        <div>
          Number: (in international format)
          <b-input v-model="newMessageNumber" />
        </div>
        <div>
          Name:
          <b-input v-model="newMessageName" />
        </div>
        <br /><br />
        <div>
          <b-button :disabled="newMessageNumber == '' || newMessageName == ''" variant="primary text-light" @click="
            sendMessage(
              newMessageNumber,
              `Hello ${ (newMessageName === '' ? 'there' : newMessageName).split(
                ' '
              )[0]
              },\n\n Are you available for us to chat?`
            )
          ">Hello
            {{
                (newMessageName === "" ? "there" : newMessageName).split(" ")[0]
            }}, Are you available for us to chat?</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";
import moment from "moment";
//import ApiService from "@/common/api.service";

export default {
  components: {
    KTPortlet,
    Loader
  },
  watch: {
    $route() {
      let profileId = this.$route.params.profileId;
      if (this.selectedProfile !== profileId) {
        this.selectProfile(profileId);
      }
    },
    showFooterTemplates() {
      this.$eventBus.emit("window-resize");
    }
  },
  data() {
    return {
      newMessageNumber: "",
      newMessageName: "",
      nowMinus24h: 0,
      message: "",
      profiles: null,
      selectedProfileId: null,
      messages: null,
      filteredMessages: null,
      fakeSendingMessages: [],
      showFooterTemplates: true
    };
  },
  methods: {
    filterMessages(scrollToBottom = false) {
      if (this.messages === null) {
        this.filteredMessages = null;
        return;
      }

      const self = this;
      self.dupCleanupOfMessages();
      //self.$nextTick(() => {
      let filtered = self.messages
        .filter(x => x.internal === false || x.internalTitle !== undefined)
        .sort((a, b) => (a.date == b.date ? 0 : a.date > b.date ? 1 : -1));
      let lastSender = "";
      for (let i = 0; i < filtered.length; i++) {
        filtered[i].showUserBanner = false;
        if (filtered[i].direction === lastSender) continue;
        if (filtered[i].internal === true) continue;
        filtered[i].showUserBanner = true;
        lastSender = filtered[i].direction;
      }

      //console.log(`WAM: ${ self.messages.length } / ${ filtered.length }`);
      self.filteredMessages = filtered.concat(JSON.parse(JSON.stringify(self.fakeSendingMessages)));
      if (scrollToBottom)
        setTimeout(() => {
          self.$refs.messagesPortlet.scrollToFunc("bottom");
        }, 500);
      //});
    },
    /*getSmartLinkedMessage(message) {
      if (this.$tools.isNullOrUndefined(message)) return [];
      if (this.$tools.isNullOrUndefined(message.message)) return [];
      if (this.$tools.isNullOrUndefined(message.message.body)) return [];
      let linkedSplit = message.message.body.split('https://');
      let lines = [];
      for (let i = 0 ; i < linkedSplit.length ; i++) {
        if (linkedSplit[i].endsWith('https')) {
          lines.push({type:'text', data: linkedSplit[i].substring(0, linkedSplit[i].length-5)});
          let itemSplit = linkedSplit[i+1].split(' ');
          lines.push({type:'link', data: `https${itemSplit[0]}`});;
          linkedSplit[i+1] = itemSplit[1];
        } else {
          lines.push({type:'text', data: linkedSplit[i]});
        }
      }
      return lines;
    },
    getSmartLinkedMessage2(text) {
      if (this.$tools.isNullOrUndefined(message)) return [];
      if (this.$tools.isNullOrUndefined(message.message)) return [];
      if (this.$tools.isNullOrUndefined(message.message.body)) return [];
      let linkedSplit = message.message.body.split('https://');
      let lines = [];
      for (let i = 0 ; i < linkedSplit.length ; i++) {
        if (linkedSplit[i].endsWith('https')) {
          lines.push({type:'text', data: linkedSplit[i].substring(0, linkedSplit[i].length-5)});
          let itemSplit = linkedSplit[i+1].split(' ');
          lines.push({type:'link', data: `https${itemSplit[0]}`});;
          linkedSplit[i+1] = itemSplit[1];
        } else {
          lines.push({type:'text', data: linkedSplit[i]});
        }
      }
      return lines;
    },*/
    formatDT(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    newChat() {
      this.$refs.newchatmodal.show();
    },
    selectProfile(profileId) {
      const self = this;
      if (self.profiles === null) return;
      self.$data.selectedProfileId = null;
      self.$nextTick(() => {
        self.$data.selectedProfileId = profileId;
      });
      self.updateMessages(self.$route.params.serviceId, profileId);
      /*for (let profile of self.profiles) {
        if (profile.id == profileId) {
          self.updateMessages(self.$route.params.serviceId, profileId);
          return;
        }
      }*/
    },
    sendMessage(number, message) {
      const self = this;
      self.$data.newMessageNumber = "";
      self.$data.newMessageName = "";
      this.$refs.newchatmodal.hide();
      let num = `${ number }`;
      let msg = `${ message }`.trim();
      self.$data.message = "";
      if (msg.length === 0) return;
      const fMID = `fake-${ num }-${ new Date().getTime() }`;
      self.fakeSendingMessages.push({
        "id": fMID,
        "direction": "outbound",
        "date": new Date().getTime(),
        "status": "sending",
        "internal": false,
        "message": { "body": msg },
        "read": [],
        "media": []
      });
      self.filterMessages(true);
      TLib.sendMessage(this, self.$route.params.serviceId, num, msg).then(x => {
        //console.log(x);
        self.$log.info(x);
        for (let ii = 0; ii < self.$data.fakeSendingMessages.length; ii++) {
          if (self.$data.fakeSendingMessages[ii].id === fMID) {
            self.$data.fakeSendingMessages.splice(ii, 1);
            break;
          }
        }
        self.$data.messages.push(x);
        self.filterMessages(true);
      }).catch(x => {
        self.$log.error(x);
        for (let ii = 0; ii < self.$data.fakeSendingMessages.length; ii++) {
          if (self.$data.fakeSendingMessages[ii].id === fMID) {
            self.$data.fakeSendingMessages[ii].status = 'error';
            //self.$data.fakeSendingMessages.splice(ii, 1);
            break;
          }
        }
      });
    },
    updateMessages(serviceId, profileId) {
      const self = this;
      this.$data.messages = null;
      this.$data.fakeSendingMessages = [];
      this.filterMessages();
      TLib.getMessages(this, serviceId, profileId).then(x => {
        self.$data.messages = x.data;
        self.filterMessages(true);
        /*let max = 1000000;
          let xup = 0;
          let intv = setInterval(() => {
            if (max <= 0) clearInterval(intv);
            max--;
            xup++;
            self.$refs.messagesPortlet.scrollToFunc(xup);
          }, 10);*/
      });
    },
    updateProfiles(serviceId) {
      const self = this;
      this.$data.profiles = null;
      self.$data.selectedProfile = null;
      this.$data.messages = null;
      this.filterMessages();
      TLib.getProfiles(this, serviceId).then(x => {
        self.$data.profiles = x.data;

        let profileId = self.$route.params.profileId;
        if (self.selectedProfile !== profileId) {
          self.selectProfile(profileId);
        }
      });
    },
    /*updateService(serviceId) {
      let self = this;
      self.$data.stats = null;
      self.$data.terms = null;
      TLib.getAdBudStats(self, serviceId)
        .then(z => {
          self.$data.stats = z.data;
          self.$data.start = z.start;
          self.$data.end = z.end;
        })
        .catch(self.$log.error);
      TLib.getAdBudTerms(self, serviceId)
        .then(z => {
          self.$data.terms = z;
        })
        .catch(self.$log.error);
     }*/
    dupCleanupOfMessages() {
      let idsList = [];
      let dupIds = [];
      for (let i = 0; i < this.messages.length; i++) {
        if (idsList.indexOf(this.messages[i].id) >= 0) {
          dupIds.push(this.messages[i].id);
          continue;
        }
        idsList.push(this.messages[i].id);
      }
      if (dupIds.length > 0) {
        for (let i = 0; i < this.messages.length; i++) {
          if (dupIds.indexOf(this.messages[i].id) >= 0) {
            this.$data.messages.splice(i, 1);
            i = 0;
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    conversationTimeLeft() {
      if (this.selectedProfile === null) return null;
      let totalLeft =
        this.selectedProfile.lastRecievedMessage - this.nowMinus24h;
      if (totalLeft < 1000)
        return { text: `${ totalLeft.toFixed(0) }ms`, urgent: true, warn: true };
      totalLeft = totalLeft / 1000;
      if (totalLeft < 60)
        return { text: `${ totalLeft.toFixed(0) }s`, urgent: true, warn: true };
      totalLeft = totalLeft / 60;
      if (totalLeft < 60)
        return { text: `${ totalLeft.toFixed(0) }m`, urgent: true, warn: true };
      totalLeft = totalLeft / 60;
      if (totalLeft < 60)
        return {
          text: `${ totalLeft.toFixed(0) }h`,
          urgent: totalLeft <= 2,
          warn: totalLeft <= 12
        };
      totalLeft = totalLeft / 24;
      return { text: `${ totalLeft.toFixed(0) }d`, urgent: false, warn: false };
    },

    config() {
      return this.layoutConfig();
    },

    selectedProfile() {
      if (this.selectedProfileId === null) return null;
      if (this.profiles === null) return null;
      for (let profile of this.profiles) {
        if (profile.id === this.selectedProfileId) return profile;
      }
      return null;
    },
    filteredProfiles() {
      if (this.profiles === null) return null;

      const self = this;
      let filtered = this.profiles
        .map(profile => {
          profile.locked =
            (profile.lastRecievedMessage || 0) < self.nowMinus24h;
          profile.urgent =
            (profile.lastRecievedMessage || 0) <
            self.nowMinus24h + 3 * 60 * 60 * 1000;
          profile.waitingReply =
            (profile.lastSentMessage || 0) <
            (profile.lastPersonalRecievedMessage || 0);
          return profile;
        })
        .sort((a, b) => {
          if (a.waitingReply) return 1;
          //if (a.locked) return -1;
          if (a.urgent) return 1;
          return a.lastInteraction == b.lastInteraction
            ? 0
            : a.lastInteraction < b.lastInteraction
              ? 1
              : -1;
        });

      return filtered;
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    this.$eventBus.off(`ws-communication-whatsapp`);
    this.$eventBus.off(`cron-minute`);
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Communication" },
      { title: "WhatsApp" }
    ]);

    this.updateProfiles(this.$route.params.serviceId);

    let self = this;

    const setTimeInfo = () => {
      self.$data.nowMinus24h =
        new Date().getTime() - 24 * 60 * 60 * 1000 - 60000;
    };
    self.$eventBus.on(`cron-minute`, setTimeInfo);
    setTimeInfo();
    self.$eventBus.on(`switch-service-selected`, x => self.updateProfiles(x));
    self.$eventBus.on(`ws-communication-whatsapp`, x => {
      if (x.data.serviceId !== self.$route.params.serviceId) return;

      switch (x.action) {
        case "profile": {
          if (self.$tools.isNullOrUndefined(self.profiles)) return;
          for (let i = 0; i < self.profiles.length; i++) {
            if (self.profiles[i].id === x.data.data.id) {
              self.$data.profiles.splice(i, 1);
              //self.$data.profiles[i] = x.data.data;
              //return;
            }
          }
          self.$data.profiles.unshift(x.data.data);
          return;
        }
        case "message": {
          if (self.$tools.isNullOrUndefined(self.messages)) return;
          if (self.selectedProfile !== null)
            if (x.data.profileId !== self.selectedProfile.id) return;

          self.dupCleanupOfMessages();

          for (let i = 0; i < self.messages.length; i++) {
            if (self.messages[i].id === x.data.data.id) {
              self.$data.messages[i] = x.data.data;
              self.filterMessages(true);
              return;
            }
          }
          self.$data.messages.push(x.data.data);
          self.filterMessages(true);

          return;
        }
      }
    });
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  }
};
</script>

<style lang="sass" scoped>
@import "@/assets/sass/custom/base/_functions"
@import "@/assets/sass/custom/base/_mixins"
@import "@/assets/sass/custom/components/_mixins"
@import "@/assets/sass/custom/_variables.bootstrap"
@import "@/assets/sass/custom/_variables.custom"
@import "@/assets/sass/custom/components/_offcanvas"
@import "@/assets/sass/custom/components/_symbol"
@import "@/assets/sass/custom/components/_label"
@import "@/assets/sass/custom/_bootstrap"
</style>

<style scoped>
.max-w-400px {
  max-width: 80%;
}
</style>